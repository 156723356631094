.singleBlogForm form input[type="email"] {
    width: 40%;
    font-family: 'open sans';
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 1.5rem;
    margin: 0;
    padding: 1rem 1.5rem 1rem 0;
}
.breadTxt p{
    width: 40%;
}
.js-height-full .col-sm-12,
.no-mb .col-sm-12{
    padding:0;
}
.no-mb{
    overflow-x: hidden;
}
.star .cover{
    width: calc(100% + 15px)
}
